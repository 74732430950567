import _ from 'underscore';
import firebase from 'firebase';
import firebaseui from 'firebaseui';

import Observable from './Observable';
import Contact from './Contact';

import FirebaseDatabase from './FirebaseDatabase';
import FirebaseStorage from './FirebaseStorage';

import DataAccessLayer from './DataAccessLayer';

import { getAjaxRequest } from './AjaxHelper';
import { hideMainUIComponents, showFirebaseLogin, hideFirebaseLoading } from './UIHelper';
import { IHUNTER_URL, isProduction, isLocal, USE_DEV_SERVER_WHEN_LOCAL } from './Environment';

const EVENT = {
    SIGN_IN: 'sign-in',       // User sign-in
    SIGN_OUT: 'sign-out',     // User sign-out
    TOKEN_REFRESH: 'token-refresh',    // Token refresh
};

const FIREBASE_CONFIG_PROD = {
    apiKey: "AIzaSyBheSYWe9kAHOrwGkRJQCE4RUjXqTdhOE4",
    authDomain: "auth.web.ihunterapp.com",//"ihunter-d5eab.firebaseapp.com",
    databaseURL: "https://ihunter-d5eab.firebaseio.com",
    functionURL: "https://api.ihunterapp.com", //"https://us-central1-ihunter-d5eab.cloudfunctions.net",
    projectId: "ihunter-d5eab",
    storageBucket: "ihunter-d5eab.appspot.com",
    messagingSenderId: "197032327386",
    appId: "1:197032327386:web:80f24799bade0ebc",
    measurementId: "G-RYL6MJ5KCW"
};
const FIREBASE_CONFIG_DEV = {
    apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
    authDomain: "ihunterdev.firebaseapp.com",
    databaseURL: "https://ihunterdev.firebaseio.com",
    functionURL:  "https://dev.api.ihunterapp.com", //"https://us-central1-ihunterdev.cloudfunctions.net",
    projectId: "ihunterdev",
    storageBucket: "ihunterdev.appspot.com",
    messagingSenderId: "30374332665",
    appId: "1:30374332665:web:6719656da28bfefe",
    measurementId: "G-VYZKNQHB2Q"
};
const FIREBASE_CONFIG_LOCAL = { // Mostly dev, but localhost functions (change as-needed for local emulators)
    apiKey: "AIzaSyCESYNTnIV-x-BDKMWs-N9UZc64Oi_0ZFQ",
    authDomain: "ihunterdev.firebaseapp.com",
    databaseURL: "https://ihunterdev.firebaseio.com",
    functionURL: "http://localhost:5001/ihunterdev/us-central1",
    projectId: "localhost",
    storageBucket: "ihunterdev.appspot.com",
    messagingSenderId: "30374332665",
    appId: "1:30374332665:web:6719656da28bfefe",
    measurementId: "G-VYZKNQHB2Q"
};

export function Configuration() {
    if(isProduction()) {
        return FIREBASE_CONFIG_PROD;
    }
    if(!USE_DEV_SERVER_WHEN_LOCAL && isLocal()) {

        return FIREBASE_CONFIG_LOCAL;
    }
    return FIREBASE_CONFIG_DEV;
}

export default class FirebaseService extends Observable {

    constructor() {
        super();

        this.database = null; 
        this.storage = null;
        this.config = Configuration();

        this.userContact = null;
        this.accessToken = null; // iHunter API Token

        this.onSignIn = this.createHandlerMethod(EVENT.SIGN_IN);
        this.onSignOut = this.createHandlerMethod(EVENT.SIGN_OUT);
        this.onTokenRefresh = this.createHandlerMethod(EVENT.TOKEN_REFRESH);
    }

    get db() { // public
        return this.database;
    }

    initialize() {

        firebase.initializeApp(this.config);
        firebase.analytics();

        this.registerListeners();

        this.database = new FirebaseDatabase(this);
        this.storage = new FirebaseStorage(this);

        this.dal = new DataAccessLayer(this);
    }

    registerListeners() {

        firebase.auth().onAuthStateChanged((user) => {
        if(user) {
            this.handleSignIn(user);
            firebase.analytics().setUserId(user.uid);
        } else {
            this.handleSignOut();
        }
        });

        firebase.auth().onIdTokenChanged((user) => {
          this.handleTokenRefresh(user);
        });
    }

    async sendPasswordResetEmail() {
        if(!this.user.email) {
            throw new Error("Unknown email");
        }
        return await firebase.auth().sendPasswordResetEmail(this.user.email);
    }

    async updateEmail(email) {
        return await firebase.auth().currentUser.updateEmail(email);
    }

    async reauthenticateWithCredential(password) {
        let credential = firebase.auth.EmailAuthProvider.credential(this.user.email, password);
        return await firebase.auth().currentUser.reauthenticateWithCredential(credential)
    }

    async signOut() {
        if(this.user) {
            this.db.logout();
            await firebase.auth().signOut();
        }
    }

    async handleSignIn(user) {

        try {
            // Check to ensure user exists in our db
            let existingUser = await this.db.getUserNodeExists();
            if(!existingUser) {
                // Create the user
                await this.db.updateUserNode(this.user.displayName);
                this.updateUserContact(true);

                this.db.onUpdateProfileName((uid, username) => {
                    this.updateUserContact(true);
                });
            }


            //this.reportExceessiveSeatCount();

        }catch(error) {
            console.error(`Error handling sign-in`);
            console.error(error);

        }finally {
            this.emit(EVENT.SIGN_IN, user);
        }
    }

    handleSignOut() {

        try {
            hideMainUIComponents();
            showFirebaseLogin();

            this.showAuthUI();

        }catch(error) {
            console.error(`Error handling sign-out`);
            console.error(error);

        }finally {
            this.emit(EVENT.SIGN_OUT,);
        }
        
    }

    async handleTokenRefresh(user) {

        try {
            if(!user) {
                console.log(`Not authenticated to Firebase`);
                
                if(this.accessToken) {
                    console.log(`Revoking iHunter access token`);
                    this.accessToken = null;
                }
                return;
            }

            console.log(`Refreshing iHunter access token`); 
            this.accessToken = await this.requestAccessToken();

        }catch(error) {
            console.error(`Failed to receive iHunter access token`);
            console.error(error);

        }finally {
            this.emit(EVENT.TOKEN_REFRESH); // Not sure this is being used yet...
        }
    }

    requestActiveProvince() {
        this.db.requestActiveProvince();
    }

    setActiveProvince(provinceCode) {
        this.db.setActiveProvince(provinceCode); 
    }

    updateUserContact(bForce = false) {
        if(!this.userContact || bForce) {
            this.userContact = new Contact(this.user.uid, null);
            this.userContact.initWithData(this, this.user.email, this.user.displayName, this.user.photoURL);
        } 
        return this.userContact;
    }

    showAuthUI() {
        // Initialize the FirebaseUI Widget using Firebase.
        var ui = (firebaseui.auth.AuthUI.getInstance()) ? firebaseui.auth.AuthUI.getInstance() : new firebaseui.auth.AuthUI(firebase.auth());
        var uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            if(window.location.href.includes('/?mode=select')) {
                return true;
            }
            return false;
            },
            uiShown: function () {
            // The widget is rendered.
            hideFirebaseLoading();
            document.getElementById('firebase-login-box').style.visibility = "visible";
            }
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        signInSuccessUrl: IHUNTER_URL.replace('/?mode=select',''), //we may need to redirect to get rid of the '/?mode=select' garbage as it messes things up!
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            'apple.com'
        ],
        // Terms of service url.
        tosUrl: 'https://www.ihunterapp.com/subscription-terms-of-use/',
        // Privacy policy url.
        privacyPolicyUrl: 'https://www.ihunterapp.com/privacy/'
        };

        ui.start('#firebaseui-auth-container', uiConfig);
        document.getElementById('firebase-login-box').style.visibility = "visible";
    }


    reportExceessiveSeatCount() {
        this.db.checkSeats().then((seats) => {
            console.log(`seats available: ${seats}`);

        }, (seats) => {
            console.log(`no seats: ${seats}`);
            
            //email iHunter
            _.delay(() => {
                this.db.sendEmail("*Automated Email*\r\nThis user has exceeded the allowed seats.");
            }, 10000);
        });
    }

    get user() {
        return firebase.auth().currentUser;
    }

    get isAuthenticated() {
        return this.authenticated;
    }

    requestAccessToken() {
        return new Promise(async (resolve, reject) => {

            try {
                if(!this.user) {
                    return reject(`Not authenticated to Firebase`);
                }

                let firebaseToken = await this.user.getIdToken();

                console.log(`Exchanging Firebase token for iHunter access token`);        
                var ajaxRequest = getAjaxRequest();
                if(ajaxRequest != null) {
                    ajaxRequest.onreadystatechange = () => {
                        if(ajaxRequest.readyState === 4) {
                            if(ajaxRequest.status === 200) {
                                if(ajaxRequest.responseText){
                                    let response = JSON.parse(ajaxRequest.responseText);
                                    if(response && response.access_token) {
                                        resolve(response.access_token);
                                    }
                                }      
                                reject("Unexpected token response");
                            }else {
                                reject("Request failed");
                            }
                        }
                    }
                    let query = this.config.functionURL + '/token';
                    ajaxRequest.open("GET", query, true);
                    ajaxRequest.setRequestHeader('authorization', 'Firebase ' + firebaseToken);
                    ajaxRequest.send(null);
                }
            }catch(error) {
                reject(error);
            }
        });
    }

    requestFirebaseToken() {
        return new Promise(async (resolve, reject) => {

            try {
                if(!this.user) {
                    return reject(`Not authenticated to Firebase`);
                }

                resolve(this.user.getIdToken());
            }catch(error) {
                reject(error);
            }
        });
    }

    requestExtensionToken() {
        return new Promise(async (resolve, reject) => {

            try {
                if(!this.user) {
                    return reject(`Not authenticated to Firebase`);
                }

                let firebaseToken = await this.user.getIdToken();

                console.log(`Exchanging iHunter access token for iHunter extension token`);        
                var ajaxRequest = getAjaxRequest();
                if(ajaxRequest != null) {
                    ajaxRequest.onreadystatechange = () => {
                        if(ajaxRequest.readyState === 4) {
                            if(ajaxRequest.status === 200) {
                                if(ajaxRequest.responseText){
                                    let response = JSON.parse(ajaxRequest.responseText);
                                    if(response && response.access_token) {
                                        resolve(response.access_token);
                                    }
                                }
                                reject("Unexpected token response");
                            }else {
                                reject("Request failed");
                            }
                        }
                    }
                    let query = this.config.functionURL + '/token';
                    ajaxRequest.open("GET", query, true);
                    ajaxRequest.setRequestHeader('type', 'extension');
                    ajaxRequest.setRequestHeader('authorization', 'Firebase ' + firebaseToken);
                    ajaxRequest.send(null);
                }
            }catch(error) {
                reject(error);
            }
        });
    }

    // Test method for profiling php authorization timing
    checkAccessToken() {
        return new Promise((resolve, reject) => {
            if(!this.accessToken) {
                return reject("No token found");
            }

            var ajaxRequest = getAjaxRequest();
            if(ajaxRequest != null) {
                ajaxRequest.onreadystatechange = () => {
                    if(ajaxRequest.readyState === 4) {
                        if(ajaxRequest.status === 200) {
                            if(ajaxRequest.responseText){
                                let scope = ajaxRequest.responseText;
                                if(scope) {
                                    return resolve(JSON.parse(scope));
                                }
                            }                           
                        }
                        return reject("Token check request failed");
                    }
                }
                ajaxRequest.open("GET", "checkToken.php", true);
                ajaxRequest.setRequestHeader('authorization', 'Bearer ' + this.accessToken);
                ajaxRequest.send(null);
            }else {

                return reject("Ajax request error");
            }   
        });
    }

}
